//
//
//
//
//
//
//
//
//
//
//

import Header from "~/components/Header.vue";
import Nav from "~/components/Nav.vue";

export default {
  components: {
    Header,
    Nav
  }
}
