//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cookies from "js-cookie";

export default {
  name: 'Header',
  data () {
    return {
      storeDomain: ""
    }
  },
  methods: {
    logout() {
      Cookies.remove('token');
      Cookies.remove('shop');
      this.$router.push('/login');
    },
  },
  mounted() {
    this.storeDomain = Cookies.get('shop');
  }
}
