import { Middleware } from '@nuxt/types'
import Cookies from 'js-cookie'

const authenticated: Middleware = ({ app, store, route, redirect}) => {
    if(route.query.shop !== undefined && route.query.t !== undefined) {
        Cookies.set('shop', route.query.shop, {expires: 3});
        Cookies.set('token', route.query.t, {expires: 3});
    } else {
        const accessToken = Cookies.get('token');
        const shopDomain = Cookies.get('shop');
        if (shopDomain && accessToken) {
            console.log('Logged in');
        } else {
            return redirect('/login');
        }
    }
}

export default authenticated